import i18n from '@/i18n'
import Main from '@/views/client/Main'
import Overview from '@/views/client/newGrid/Overview'

const Task = () =>
  import(/* webpackChunkName: "task" */ '@/views/client/newGrid/task/Task')
const EditorMain = () =>
  import(
    /* webpackChunkName: "editor" */ '@/views/client/newGrid/editor/EditorMain'
  )

// Translators
const Translators = () =>
  import(
    /* webpackChunkName: "translators" */ '@/views/client/newGrid/translators/Translators'
  )
// Translator team build
const TeamSteps = () =>
  import(
    /* webpackChunkName: "team-steps" */ '@/views/client/newGrid/team/TeamSteps'
  )
const TeamStepCopywriting = () =>
  import(
    /* webpackChunkName: "team-steps" */ '@/views/client/newGrid/team/TeamStepCopywriting'
  )

const TeamStepCopywritingConfirm = () =>
  import(
    /* webpackChunkName: "team-steps" */ '@/views/client/newGrid/team/TeamStepCopywritingConfirm'
  )

const TeamStepOne = () =>
  import(
    /* webpackChunkName: "team-steps-one" */ '@/views/client/newGrid/team/TeamStepOne'
  )
const TeamStepTwo = () =>
  import(
    /* webpackChunkName: "team-steps-two" */ '@/views/client/newGrid/team/TeamStepTwo'
  )
const TeamStepThree = () =>
  import(
    /* webpackChunkName: "team-steps-three" */ '@/views/client/newGrid/team/TeamStepThree'
  )
const TeamStepFour = () =>
  import(
    /* webpackChunkName: "team-steps-four" */ '@/views/client/newGrid/team/TeamStepFour'
  )
const TeamStepFive = () =>
  import(
    /* webpackChunkName: "team-steps-five" */ '@/views/client/newGrid/team/TeamStepFive'
  )
// Folder
const Folders = () =>
  import(
    /* webpackChunkName: "folders" */ '@/views/client/newGrid/folder/Folders'
  )
const Folder = () =>
  import(
    /* webpackChunkName: "folder" */ '@/views/client/newGrid/folder/Folder'
  )

// Project
const Projects = () =>
  import(
    /* webpackChunkName: "projects" */ '@/views/client/newGrid/project/Projects'
  )
const Project = () =>
  import(
    /* webpackChunkName: "project" */ '@/views/client/newGrid/project/Project'
  )

// Payment
const Payment = () =>
  import(
    /* webpackChunkName: "payment" */ '@/views/client/newGrid/payment/Payment'
  )
const PaymentBilling = () =>
  import(
    /* webpackChunkName: "payment-billing" */ '@/views/client/newGrid/payment/PaymentBilling'
  )
const PaymentUsage = () =>
  import(
    /* webpackChunkName: "payment-usage" */ '@/views/client/newGrid/payment/PaymentUsage'
  )
const PaymentWallet = () =>
  import(
    /* webpackChunkName: "payment-wallet" */ '@/views/client/newGrid/payment/PaymentWallet'
  )
const PaymentBalance = () =>
  import(
    /* webpackChunkName: "payment-balance" */ '@/views/client/newGrid/payment/PaymentBalance'
  )
const PaymentHistory = () =>
  import(
    /* webpackChunkName: "payment-history" */ '@/views/client/newGrid/payment/PaymentHistory'
  )

// Settings view
const Account = () =>
  import(
    /* webpackChunkName: "account" */ '@/views/client/newGrid/account/Account'
  )
const AccountStructure = () =>
  import(
    /* webpackChunkName: "account-structure" */ '@/views/client/newGrid/account/AccountStructure'
  )
const AccountInfo = () =>
  import(
    /* webpackChunkName: "account-info" */ '@/views/client/newGrid/account/AccountInfo'
  )
const Mfa = () => import(/* webpackChunkName: "m-f-a" */ '@/views/shared/Mfa')

const Sso = () =>
  import(/* webpackChunkName: "s-s-o" */ '@/views/client/newGrid/Sso')

const Billing = () =>
  import(/* webpackChunkName: "billing" */ '@/views/client/newGrid/Billing')
const UserPermission = () =>
  import(
    /* webpackChunkName: "user-permission" */ '@/views/client/newGrid/UserPermission'
  )
const Profile = () =>
  import(/* webpackChunkName: "profile" */ '@/views/client/newGrid/Profile')
const Privacy = () =>
  import(/* webpackChunkName: "privacy" */ '@/views/client/newGrid/Privacy')
const PriceLimit = () =>
  import(
    /* webpackChunkName: "price-limit" */ '@/views/client/newGrid/pricing/PriceLimit'
  )
const ReviewPrice = () =>
  import(
    /* webpackChunkName: "review-price" */ '@/views/client/newGrid/pricing/ReviewPrice'
  )
const Reports = () =>
  import(/* webpackChunkName: "reports" */ '@/views/client/newGrid/Reports')
const CustomerGDPR = () =>
  import(
    /* webpackChunkName: "customer-g-d-p-r" */ '@/views/client/newGrid/account/CustomerGDPR'
  )

//News
const News = () => import(/* webpackChunkName: "news" */ '@/views/shared/News')

const TranslationPrice = () =>
  import(
    /* webpackChunkName: "translation-price" */ '@/views/client/newGrid/translation/TranslationPrice'
  )
const RolesPermissions = () =>
  import(
    /* webpackChunkName: "roles-permissions" */ '@/views/client/newGrid/RolesPermissions'
  )

//  Automation flows
const AutomationFlows = () =>
  import(
    /* webpackChunkName: "automation-flows" */ '@/views/client/newGrid/automation/AutomationFlows'
  )
const AutomationFlow = () =>
  import(
    /* webpackChunkName: "automation-flow" */ '@/views/client/newGrid/automation/AutomationFlow'
  )

// libraries
const Libraries = () =>
  import(
    /* webpackChunkName: "libraries" */ '@/views/client/newGrid/libraries/Libraries'
  )
const Library = () =>
  import(
    /* webpackChunkName: "library" */ '@/views/client/newGrid/libraries/Library'
  )

// Marketplace
const Marketplace = () =>
  import(
    /* webpackChunkName: "App marketplace" */ '@/views/client/newGrid/marketplace/Marketplace'
  )
const MarketplaceZapierConnect = () =>
  import(
    /*webpackChunkName: "app-marketplace-zapier-connect" */ '@/views/client/newGrid/marketplace/MarketplaceZapierConnect'
  )
// Progress
const ProgressOnboarding = () =>
  import(
    /* webpackChunkName: "Progress onboarding" */ '@/views/client/newGrid/progress/ProgressOnboarding'
  )

const Chat = () =>
  import(/* webpackChunkName: "chat" */ '@/views/client/chat/Chat')

//Content generation
const ContentGeneration = () =>
  import(
    /* webpackChunkName: "content-generation-main" */ '@/views/client/newGrid/contentGeneration/ContentGeneration'
  )
const CaasDocuments = () =>
  import(
    /* webpackChunkName: "documents" */ '@/views/client/newGrid/contentGeneration/CaasDocuments'
  )
const CaasTemplates = () =>
  import(
    /* webpackChunkName: "templates" */ '@/views/client/newGrid/contentGeneration/CaasTemplates'
  )

const PillarsMain = () =>
  import(
    /* webpackChunkName: "pillars-main" */ '@/views/client/content-pillars/pillars/PillarsMain'
  )
const PillarsOverview = () =>
  import(
    /* webpackChunkName: "pillars-overview" */ '@/views/client/content-pillars/pillars/PillarsOverview'
  )

const CreatePillarStart = () =>
  import(
    /* webpackChunkName: "create-pillar-start" */ '@/views/client/content-pillars/pillars/CreatePillarStart'
  )

const CreatePillarDescription = () =>
  import(
    /* webpackChunkName: "create-pillar-description" */ '@/views/client/content-pillars/pillars/CreatePillarDescription'
  )

const CreatePillarKeywords = () =>
  import(
    /* webpackChunkName: "create-pillar-keywords" */ '@/views/client/content-pillars/pillars/CreatePillarKeywords'
  )
const CreatePillarMain = () =>
  import(
    /* webpackChunkName: "create-pillar-main" */ '@/views/client/content-pillars/pillars/CreatePillarMain'
  )
const ClustersMain = () =>
  import(
    /* webpackChunkName: "clusters-main" */ '@/views/client/content-pillars/clusters/ClustersMain'
  )
const ClustersOverview = () =>
  import(
    /* webpackChunkName: "clusters-overview" */ '@/views/client/content-pillars/clusters/ClustersOverview'
  )
const CreateContentMain = () =>
  import(
    /* webpackChunkName: "create-content-main" */ '@/views/client/content-pillars/clusters/CreateContentMain'
  )
const CreateContentStart = () =>
  import(
    /* webpackChunkName: "create-content-start" */ '@/views/client/content-pillars/clusters/CreateContentStart'
  )
const CreateContentSuggestions = () =>
  import(
    /* webpackChunkName: "create-content-suggestions" */ '@/views/client/content-pillars/clusters/CreateContentSuggestions'
  )
const CreateContentLanguages = () =>
  import(
    /* webpackChunkName: "create-content-languages" */ '@/views/client/content-pillars/clusters/CreateContentLanguages'
  )
const CreateContentResults = () =>
  import(
    /* webpackChunkName: "create-content-results" */ '@/views/client/content-pillars/clusters/CreateContentResults'
  )
// PhraseConnector
const PhraseConnector = () =>
  import(
    /* webpackChunkName: "phrase-connector" */ '@/views/client/newGrid/phraseConnector/PhraseConnector'
  )
const PhraseConnectorStepOne = () =>
  import(
    /* webpackChunkName: "phrase-connector-step-one" */ '@/views/client/newGrid/phraseConnector/PhraseConnectorStepOne'
  )
const PhraseConnectorStepTwo = () =>
  import(
    /* webpackChunkName: "phrase-connector-step-two" */ '@/views/client/newGrid/phraseConnector/PhraseConnectorStepTwo'
  )
const PhraseConnectorStepThree = () =>
  import(
    /* webpackChunkName: "phrase-connector-step-three" */ '@/views/client/newGrid/phraseConnector/PhraseConnectorStepThree'
  )
const PhraseConnectorRedirect = () =>
  import(
    /* webpackChunkName: "phrase-connector-redirect" */ '@/views/client/newGrid/phraseConnector/PhraseConnectorRedirect'
  )
// LanguagePairing
const LanguagePairing = () =>
  import(
    /* webpackChunkName: "language-pairing" */ '@/views/client/newGrid/phraseConnector/LanguagePairing'
  )

// Language Assets
const LanguageAssets = () =>
  import(
    /* webpackChunkName: "language-assets" */ '@/views/client/newGrid/languageAssets/LanguageAssets'
  )
const TranslationMemories = () =>
  import(
    /* webpackChunkName: "translation-memories" */ '@/views/client/newGrid/languageAssets/overview/TranslationMemories'
  )
const TranslationMemory = () =>
  import(
    /* webpackChunkName: "translation-memory" */ '@/views/client/newGrid/languageAssets/singleView/TranslationMemory'
  )
const TermBanks = () =>
  import(
    /* webpackChunkName: "glossaries" */ '@/views/client/newGrid/languageAssets/overview/TermBanks'
  )
const TermBanksNew = () =>
  import(
    /* webpackChunkName: "glossaries" */ '@/views/client/newGrid/languageAssets/overview/TermBanksNew'
  )
const TermBank = () =>
  import(
    /* webpackChunkName: "glossary" */ '@/views/client/newGrid/languageAssets/singleView/TermBank'
  )
const ImportLanguageAssets = () =>
  import(
    /* webpackChunkName: "add-assets" */ '@/views/client/newGrid/languageAssets/import/ImportLanguageAssets'
  )
const ImportLanguageAssetsStart = () =>
  import(
    /* webpackChunkName: "add-assets-start" */ '@/views/client/newGrid/languageAssets/import/ImportLanguageAssetsStart'
  )
const ImportTm = () =>
  import(
    /* webpackChunkName: "add-tm" */ '@/views/client/newGrid/languageAssets/import/ImportTm'
  )
const ImportTb = () =>
  import(
    /* webpackChunkName: "add-tm" */ '@/views/client/newGrid/languageAssets/import/ImportTb'
  )

export default [
  {
    component: Main,
    path: 'customer/:teamIdentifier',
    redirect: {
      path: 'customer/:teamIdentifier/workspace/overview'
    },
    children: [
      {
        path: 'content-pillars',
        name: 'clientPillars',
        component: PillarsMain,
        meta: { userType: ['customer', 'admin'] },
        children: [
          {
            path: 'overview',
            name: 'clientPillarsOverview',
            component: PillarsOverview,
            meta: {
              baseRoute: 'clientPillars',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Content Pillars', linkTo: 'clientPillarsOverview' },
                { name: 'Overview', linkTo: 'clientPillarsOverview' }
              ]
            }
          },
          {
            path: 'create-pillar',
            name: 'clientCreatePillar',
            component: CreatePillarMain,
            meta: {
              baseRoute: 'clientPillars',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Content Pillars', linkTo: 'clientPillarsOverview' },
                { name: 'Create pillar', linkTo: 'clientCreatePillar' },
                { name: 'Start', linkTo: 'clientCreatePillarStart' }
              ]
            },
            children: [
              {
                path: 'start',
                name: 'clientCreatePillarStart',
                component: CreatePillarStart,
                meta: {
                  baseRoute: 'clientPillars',
                  userType: ['customer', 'admin'],
                  breadcrumb: [
                    {
                      name: 'Content Pillars',
                      linkTo: 'clientPillarsOverview'
                    },
                    {
                      name: 'Create pillar',
                      linkTo: 'clientCreatePillarStart'
                    },
                    { name: 'Start', linkTo: 'clientCreatePillarStart' }
                  ]
                }
              },
              {
                path: 'description',
                name: 'clientCreatePillarDescription',
                component: CreatePillarDescription,
                meta: {
                  baseRoute: 'clientPillars',
                  userType: ['customer', 'admin'],
                  breadcrumb: [
                    {
                      name: 'Content Pillars',
                      linkTo: 'clientPillarsOverview'
                    },
                    {
                      name: 'Create pillar',
                      linkTo: 'clientCreatePillarStart'
                    },
                    {
                      name: 'Description',
                      linkTo: 'clientCreatePillarDescription'
                    }
                  ]
                }
              },
              {
                path: 'keywords',
                name: 'clientCreatePillarKeywords',
                component: CreatePillarKeywords,
                meta: {
                  baseRoute: 'clientPillars',
                  userType: ['customer', 'admin'],
                  breadcrumb: [
                    {
                      name: 'Content Pillars',
                      linkTo: 'clientPillarsOverview'
                    },
                    {
                      name: 'Create pillar',
                      linkTo: 'clientCreatePillarStart'
                    },
                    {
                      name: 'Keywords',
                      linkTo: 'clientCreatePillarKeywords'
                    }
                  ]
                }
              }
            ]
          },
          {
            path: 'clusters',
            name: 'clientClusters',
            component: ClustersMain,
            meta: {
              baseRoute: 'clientPillars',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Content Pillars', linkTo: 'clientPillarsOverview' }
              ]
            },
            children: [
              {
                path: ':pillarId/:pillarName',
                name: 'clientClustersOverview',
                component: ClustersOverview,
                meta: {
                  baseRoute: 'clientPillars',
                  userType: ['customer', 'admin'],
                  breadcrumb: [
                    {
                      name: 'Content Pillars',
                      linkTo: 'clientPillarsOverview'
                    }
                  ]
                }
              },
              {
                path: ':pillarId/:pillarName/create-content',
                name: 'clientClustersCreateContent',
                component: CreateContentMain,
                meta: {
                  baseRoute: 'clientPillars',
                  userType: ['customer', 'admin'],
                  breadcrumb: [
                    {
                      name: 'Content Pillars',
                      linkTo: 'clientPillarsOverview'
                    }
                  ]
                },
                children: [
                  {
                    path: 'start',
                    name: 'clientClustersPageStart',
                    component: CreateContentStart,
                    meta: {
                      baseRoute: 'clientPillars',
                      userType: ['customer', 'admin'],
                      breadcrumb: [
                        {
                          name: 'Content Pillars',
                          linkTo: 'clientPillarsOverview'
                        },
                        {
                          name: 'Create content',
                          linkTo: 'clientClustersPageStart'
                        },
                        { name: 'Start', linkTo: 'clientClustersPageStart' }
                      ]
                    }
                  },
                  {
                    path: 'suggestions',
                    name: 'clientClustersPageSuggestions',
                    component: CreateContentSuggestions,
                    meta: {
                      baseRoute: 'clientPillars',
                      userType: ['customer', 'admin'],
                      breadcrumb: [
                        {
                          name: 'Content Pillars',
                          linkTo: 'clientPillarsOverview'
                        },
                        {
                          name: 'Create content',
                          linkTo: 'clientClustersPageStart'
                        },
                        { name: 'Start', linkTo: 'clientClustersPageStart' },
                        {
                          name: 'Suggestions',
                          linkTo: 'clientClustersPageSuggestions'
                        }
                      ]
                    }
                  },
                  {
                    path: 'languages',
                    name: 'clientClustersPageLanguages',
                    component: CreateContentLanguages,
                    meta: {
                      baseRoute: 'clientPillars',
                      userType: ['customer', 'admin'],
                      breadcrumb: [
                        {
                          name: 'Content Pillars',
                          linkTo: 'clientPillarsOverview'
                        },
                        {
                          name: 'Create content',
                          linkTo: 'clientClustersPageStart'
                        },
                        { name: 'Start', linkTo: 'clientClustersPageStart' },
                        {
                          name: 'Languages',
                          linkTo: 'clientClustersPageLanguages'
                        }
                      ]
                    }
                  },
                  {
                    path: 'results',
                    name: 'clientClustersPageResults',
                    component: CreateContentResults,
                    meta: {
                      baseRoute: 'clientPillars',
                      userType: ['customer', 'admin'],
                      breadcrumb: [
                        {
                          name: 'Content Pillars',
                          linkTo: 'clientPillarsOverview'
                        },
                        {
                          name: 'Create content',
                          linkTo: 'clientClustersPageStart'
                        },
                        { name: 'Start', linkTo: 'clientClustersPageStart' },
                        {
                          name: 'Results',
                          linkTo: 'clientClustersPageResults'
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'chat',
        name: 'customerChat',
        component: Chat,
        meta: {
          baseRoute: 'customerChat',
          userType: ['customer', 'admin'],
          breadcrumb: [{ name: i18n.t('shared.label.chat') }]
        }
      },
      {
        path: 'workspace/overview',
        name: 'clientOverview',
        component: Overview,
        meta: {
          baseRoute: 'clientOverview',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.nav.overview.title') },
            {
              name: i18n.t('customer.nav.overview.btn'),
              linkTo: 'clientOverview'
            }
          ]
        }
      },
      {
        path: 'projects',
        name: 'clientProjects',
        component: Projects,
        meta: {
          baseRoute: 'clientOverview',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.overview.title'),
              linkTo: 'clientOverview'
            },
            {
              name: i18n.t('customer.sub_nav.projects.btn'),
              linkTo: 'clientProjects'
            }
          ]
        }
      },
      {
        path: 'projects/:id',
        name: 'clientProject',
        component: Project,
        meta: {
          baseRoute: 'clientOverview',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.overview.title'),
              linkTo: 'clientOverview'
            },
            {
              name: i18n.t('customer.sub_nav.projects.btn'),
              linkTo: 'clientProjects'
            }
          ]
        }
      },
      {
        path: 'projects/:id/messages',
        name: 'clientRevisionTask',
        component: Task,
        meta: {
          baseRoute: 'clientOverview',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.overview.title'),
              linkTo: 'clientOverview'
            }
          ]
        }
      },
      {
        path: 'projects/:id/tasks/:taskId',
        name: 'clientRevisionEditor',
        component: EditorMain,
        meta: {
          baseRoute: 'clientRevisionEditor',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.overview.title'),
              linkTo: 'clientOverview'
            }
          ]
        }
      },
      {
        path: 'folders',
        name: 'clientFolders',
        component: Folders,
        meta: {
          baseRoute: 'clientOverview',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.overview.title'),
              linkTo: 'clientOverview'
            },
            {
              name: i18n.t('customer.sub_nav.folders.btn'),
              linkTo: 'clientFolders'
            }
          ]
        }
      },
      {
        path: 'folders/:id',
        name: 'clientFolder',
        component: Folder,
        meta: {
          baseRoute: 'clientOverview',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.overview.title'),
              linkTo: 'clientOverview'
            },
            {
              name: i18n.t('customer.sub_nav.folders.btn'),
              linkTo: 'clientFolders'
            }
          ]
        }
      },
      {
        path: 'settings/billing',
        name: 'clientSettings',
        component: Billing,
        meta: { userType: ['customer', 'admin'] },
        children: [
          {
            path: 'billing',
            name: 'clientPaymentBilling',
            component: PaymentBilling,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.billing.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'wallet',
            name: 'clientPaymentWallet',
            component: PaymentWallet,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.wallet.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'balance',
            name: 'clientPaymentBalance',
            component: PaymentBalance,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.balance.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'usage',
            name: 'clientUsage',
            component: PaymentUsage,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: i18n.t('customer.sub_nav.usage.btn'), linkTo: null }
              ]
            }
          },
          {
            path: 'history',
            name: 'clientPaymentHistory',
            component: PaymentHistory,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.sub_nav.history.btn'), linkTo: null }
              ]
            }
          },
          {
            path: 'price-limit',
            name: 'clientPriceLimit',
            component: PriceLimit,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.price_limit.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'review-price-list',
            name: 'clientReviewPriceList',
            component: ReviewPrice,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.revision_price.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'translation-price-list',
            name: 'clientTranslationPrice',
            component: TranslationPrice,
            meta: {
              group: 'billing',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.translation_price.btn'),
                  linkTo: null
                }
              ]
            }
          }
        ]
      },
      {
        path: 'settings/account',
        name: 'clientAccount',
        component: Account,
        meta: {
          baseRoute: 'clientSettings',
          userType: ['customer', 'admin']
        },
        children: [
          {
            path: 'account-info/',
            name: 'clientAccountInfo',
            component: AccountInfo,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.account_info.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'mfa',
            name: 'customerMfa',
            component: Mfa,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [{ name: '2FA', linkTo: null }]
            }
          },
          {
            path: 'sso',
            name: 'customerSso',
            component: Sso,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [{ name: 'SSO', linkTo: null }]
            }
          },
          {
            path: 'profile',
            name: 'clientProfile',
            component: Profile,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: i18n.t('customer.sub_nav.profile.btn'), linkTo: null }
              ]
            }
          },
          {
            path: 'privacy-consent',
            name: 'clientPrivacyConsent',
            component: Privacy,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: i18n.t('customer.sub_nav.privacy.btn'), linkTo: null }
              ]
            }
          },
          {
            path: 'translation-reports',
            name: 'clientReports',
            component: Reports,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: i18n.t('customer.sub_nav.reports.btn'), linkTo: null }
              ]
            }
          },
          {
            path: 'gdpr',
            name: 'customerGDPR',
            component: CustomerGDPR,
            meta: {
              group: 'account',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [{ name: 'GDPR', linkTo: null }]
            }
          }
        ]
      },
      {
        path: 'settings/team',
        name: 'clientTeam',
        component: Account,
        meta: {
          baseRoute: 'clientSettings',
          userType: ['customer', 'admin']
        },
        children: [
          {
            path: 'user-permission',
            name: 'clientUserPermissions',
            component: UserPermission,
            meta: {
              group: 'team',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.users_permission.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'account-structure',
            name: 'clientAccountStructure',
            component: AccountStructure,
            meta: {
              group: 'team',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.account_structor.btn'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'roles-permission',
            name: 'clientRolesPermissions',
            component: RolesPermissions,
            meta: {
              group: 'team',
              baseRoute: 'clientSettings',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.sub_nav.roles_permission.btn'),
                  linkTo: null
                }
              ]
            }
          }
        ]
      },
      {
        path: 'translators',
        name: 'clientTranslators',
        component: Translators,
        meta: {
          baseRoute: 'clientTranslators',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.nav.translators.btn'), linkTo: null }
          ]
        }
      },
      {
        path: 'translators/build-team',
        name: 'clientTeamSteps',
        component: TeamSteps,
        meta: {
          baseRoute: 'clientTeamSteps',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.nav.translators.btn'), linkTo: null }
          ]
        },
        children: [
          {
            path: '1',
            name: 'clientTeamStepOne',
            component: TeamStepOne,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: '2',
            name: 'clientTeamStepTwo',
            component: TeamStepTwo,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: '3',
            name: 'clientTeamStepThree',
            component: TeamStepThree,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: '4',
            name: 'clientTeamStepFour',
            component: TeamStepFour,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'copy-editing',
            name: 'clientTeamStepCopywriting',
            component: TeamStepCopywriting,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: '5',
            name: 'clientTeamStepFive',
            component: TeamStepFive,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'confirm-copywriters',
            name: 'clientTeamStepCopywritingConfirm',
            component: TeamStepCopywritingConfirm,
            meta: {
              baseRoute: 'clientTranslators',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.nav.translators.btn'),
                  linkTo: 'clientTranslators'
                },
                {
                  name: i18n.t('customer.nav.translators.breadcrumb'),
                  linkTo: null
                }
              ]
            }
          }
        ]
      },
      {
        path: 'automation-flows',
        name: 'clientAutomationFlows',
        component: AutomationFlows,
        meta: {
          baseRoute: 'clientAutomationFlows',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.automation_workflows.btn'),
              linkTo: null
            }
          ]
        }
      },
      {
        path: 'automation-flows/:id',
        name: 'clientAutomationFlow',
        component: AutomationFlow,
        meta: {
          baseRoute: 'clientAutomationFlow',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.automation_workflows.btn'),
              linkTo: 'clientAutomationFlows'
            }
          ]
        }
      },
      {
        path: 'libraries',
        name: 'clientLibrariesLayout',
        component: Libraries,
        meta: {
          baseRoute: 'clientLibraries',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.nav.string_libraries.btn'), linkTo: null }
          ]
        }
      },
      {
        path: 'libraries/:id',
        name: 'clientLibrary',
        component: Library,
        meta: {
          baseRoute: 'clientLibraries',
          userType: ['customer', 'admin'],
          breadcrumb: [
            {
              name: i18n.t('customer.nav.string_libraries.btn'),
              linkTo: 'clientLibrariesLayout'
            }
          ]
        }
      },
      {
        path: 'app-marketplace',
        name: 'clientMarketplace',
        component: Marketplace,
        meta: {
          baseRoute: 'clientMarketplace',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.nav.marketplace.btn'), linkTo: null }
          ]
        }
      },
      {
        path: 'app-marketplace/zapier-connect',
        name: 'clientZapierConnect',
        component: MarketplaceZapierConnect,
        meta: {
          baseRoute: 'clientZapierConnect',
          userType: ['customer', 'admin']
        }
      },
      {
        path: 'dashboard',
        name: 'clientProgressOnboarding',
        component: ProgressOnboarding,
        meta: {
          baseRoute: 'clientProgressOnboarding',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.nav.progress.title'), linkTo: null }
          ]
        }
      },
      {
        path: 'news',
        name: 'news',
        component: News,
        meta: {
          baseRoute: 'news',
          userType: ['customer', 'admin'],
          breadcrumb: [{ name: i18n.t('shared.nav.news.title'), linkTo: null }]
        }
      },
      {
        path: 'content-generation',
        name: 'contentGenerationV2',
        component: ContentGeneration,
        meta: {
          baseRoute: 'contentGenerationV2',
          userType: ['customer', 'admin'],
          breadcrumb: [{ name: i18n.t('shared.caas.title'), linkTo: null }]
        }
      },
      {
        path: 'content-generation/documents',
        name: 'caasDocuments',
        component: CaasDocuments,
        meta: {
          baseRoute: 'contentGenerationV2',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.sub_nav.caas.documents'), linkTo: null }
          ]
        }
      },
      {
        path: 'content-generation/templates',
        name: 'caasTemplates',
        component: CaasTemplates,
        meta: {
          baseRoute: 'contentGenerationV2',
          userType: ['customer', 'admin'],
          breadcrumb: [
            { name: i18n.t('customer.sub_nav.caas.templates'), linkTo: null }
          ]
        }
      },
      {
        path: 'phrase-connector',
        name: 'clientPhraseConnector',
        component: PhraseConnector,
        meta: {
          baseRoute: 'clientPhraseConnector',
          userType: ['customer', 'admin'],
          breadcrumb: [{ name: 'Phrase Connector', linkTo: null }]
        },
        children: [
          {
            path: '1',
            name: 'clientPhraseConnectorStepOne',
            component: PhraseConnectorStepOne,
            meta: {
              group: 'phrase-connector',
              baseRoute: 'clientPhraseConnector',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Phrase Connector', linkTo: null },
                { name: 'Enable connection', linkTo: null }
              ]
            }
          },
          {
            path: '2',
            name: 'clientLanguagePairing',
            component: LanguagePairing,
            meta: {
              baseRoute: 'clientPhraseConnector',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Phrase Connector', linkTo: null },
                { name: 'Language Pairs', linkTo: null }
              ]
            }
          },
          {
            path: '3',
            name: 'clientPhraseConnectorStepTwo',
            component: PhraseConnectorStepTwo,
            meta: {
              group: 'phrase-connector',
              baseRoute: 'clientPhraseConnector',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Phrase Connector', linkTo: null },
                { name: 'Create workflow', linkTo: null }
              ]
            }
          },
          {
            path: '4',
            name: 'clientPhraseConnectorStepThree',
            component: PhraseConnectorStepThree,
            meta: {
              group: 'phrase-connector',
              baseRoute: 'clientPhraseConnector',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Phrase Connector', linkTo: null },
                { name: 'Create task', linkTo: null }
              ]
            }
          },
          {
            path: 'redirect',
            name: 'clientPhraseConnectorRedirect',
            component: PhraseConnectorRedirect,
            meta: {
              group: 'phrase-connector',
              baseRoute: 'clientPhraseConnector',
              userType: ['customer', 'admin'],
              breadcrumb: [
                { name: 'Phrase Connector', linkTo: null },
                { name: 'Enable connection', linkTo: null }
              ]
            }
          }
        ]
      },
      {
        path: 'language-assets',
        name: 'languageAssets',
        component: LanguageAssets,
        meta: { userType: ['customer', 'admin'] },
        children: [
          {
            path: 'translation-memories',
            name: 'translationMemories',
            component: TranslationMemories,
            meta: {
              baseRoute: 'languageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.language_assets.title'),
                  linkTo: null
                },
                {
                  name: i18n.t(
                    'customer.language_assets.translation_memories.title'
                  ),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'translation-memories/:id',
            name: 'translationMemory',
            component: TranslationMemory,
            meta: {
              baseRoute: 'languageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.language_assets.title'),
                  linkTo: null
                },
                {
                  name: i18n.t(
                    'customer.language_assets.translation_memories.title'
                  ),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'glossaries',
            name: 'termBanks',
            component: TermBanks,
            meta: {
              baseRoute: 'languageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.language_assets.title'),
                  linkTo: null
                },
                {
                  name: i18n.t('customer.language_assets.term_bank.title'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'glossaries-new',
            name: 'termBanksNew',
            component: TermBanksNew,
            meta: {
              baseRoute: 'languageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.language_assets.title'),
                  linkTo: null
                },
                {
                  name: i18n.t('customer.language_assets.term_bank.title'),
                  linkTo: null
                }
              ]
            }
          },
          {
            path: 'glossaries/:id',
            name: 'termBank',
            component: TermBank,
            meta: {
              baseRoute: 'languageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('customer.language_assets.title'),
                  linkTo: null
                },
                {
                  name: i18n.t('customer.language_assets.term_bank.title'),
                  linkTo: null
                }
              ]
            }
          }
        ]
      },
      {
        path: 'add-language-assets',
        name: 'importLanguageAssets',
        component: ImportLanguageAssets,
        meta: { userType: ['customer', 'admin'] },
        children: [
          {
            path: 'start',
            name: 'langAssetsStart',
            component: ImportLanguageAssetsStart,
            meta: {
              baseRoute: 'importLanguageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [{ name: 'Start', linkTo: null }]
            }
          },
          {
            path: 'translation-memories',
            name: 'langAssetsTmForm',
            component: ImportTm,
            meta: {
              baseRoute: 'importLanguageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [{ name: 'Add Translation Memories', linkTo: null }]
            }
          },
          {
            path: 'glossaries',
            name: 'langAssetsTbForm',
            component: ImportTb,
            meta: {
              baseRoute: 'importLanguageAssets',
              userType: ['customer', 'admin'],
              breadcrumb: [{ name: 'Add Glossaries', linkTo: null }]
            }
          }
        ]
      }
    ]
  }
]
